import LoadingIcon from 'components/shared/loading-icon'
import { useAppDispatch } from 'configureStore'
import { setLogout } from 'features/auth/slice'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            dispatch(setLogout())
            window.location.assign('https://controltower.flybits.com/app/signout')
        })()
    }, [])


    return (
        <>
            <LoadingIcon width={80} height={80} fullScreen visible={true} />
        </>
    );
};

export default Logout;
