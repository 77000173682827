import { RootState } from 'configureStore'
import { anonymousLogin, generateIdToken, jwtLogin, oidcLogin } from 'features/auth/services'
import { UserData } from 'features/auth/types'
import { getApiHost } from 'utils/env'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'
export interface AuthState {
    isError: boolean,
    isLoading: boolean,
    isAuthenticated: boolean,
    user?: UserData,
    token?: string,
}

const initialState: AuthState = {
    isError: false,
    isLoading: false,
    isAuthenticated: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<boolean>) => {
            state.isError = action.payload
        },
        setLogout: (state) => {
            state.isLoading = false
            state.isAuthenticated = false
            state.isError = false
            delete state.user
            delete state.token
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authExchangeToken.pending, (state) => {
            state.isLoading = true
            state.isAuthenticated = false
            state.isError = false
            delete state.user
            delete state.token
        })
        builder.addCase(authExchangeToken.fulfilled, (state, { payload: { user, token } }) => {
            state.isLoading = false
            state.isAuthenticated = true
            state.isError = false
            state.user = user
            state.token = token
        })
        builder.addCase(authExchangeToken.rejected, (state) => {
            state.isLoading = false
            state.isAuthenticated = false
            state.isError = true
            delete state.user
            delete state.token
        })

        builder.addCase(authAnonymous.pending, (state) => {
            state.isLoading = true
            state.isAuthenticated = false
            state.isError = false
            delete state.user
            delete state.token
        })
        builder.addCase(authAnonymous.fulfilled, (state, { payload: { user, token } }) => {
            state.isLoading = false
            state.isAuthenticated = true
            state.isError = false
            state.user = user
            state.token = token
        })
        builder.addCase(authAnonymous.rejected, (state) => {
            state.isLoading = false
            state.isAuthenticated = false
            state.isError = true
            delete state.user
            delete state.token
        })

        builder.addCase(authJwt.pending, (state) => {
            state.isLoading = true
            state.isAuthenticated = false
            state.isError = false
            delete state.user
            delete state.token
        })
        builder.addCase(authJwt.fulfilled, (state, { payload: { user, token } }) => {
            state.isLoading = false
            state.isAuthenticated = true
            state.isError = false
            state.user = user
            state.token = token
        })
        builder.addCase(authJwt.rejected, (state) => {
            state.isLoading = false
            state.isAuthenticated = false
            state.isError = true
            delete state.user
            delete state.token
        })
    },
})
export const authExchangeToken = createAsyncThunk<Awaited<ReturnType<typeof oidcLogin>>, { authCode: string, codeVerifier: string, authRedirectUri: string, deployment: string }, { state: RootState }>('auth/exchangeToken',
    async ({ authCode, codeVerifier, authRedirectUri, deployment }, thunkApi) => {
        const idToken = await generateIdToken(authCode, codeVerifier, authRedirectUri, deployment)
        return await oidcLogin(idToken, getApiHost(deployment))
    }
)

export const authAnonymous = createAsyncThunk<Awaited<ReturnType<typeof anonymousLogin>>, { tenantId: string, deployment: string }, { state: RootState }>('auth/anonymousLogin',
    async ({ tenantId, deployment }, thunkApi) => {
        return await anonymousLogin(tenantId, getApiHost(deployment))
    }
)

export const authJwt = createAsyncThunk<Awaited<ReturnType<typeof jwtLogin>>, { jwt: string, deployment: string }, { state: RootState }>('auth/jwtLogin',
    async ({ jwt, deployment }, thunkApi) => {
        const user  = await jwtLogin(jwt, getApiHost(deployment))

        return user
    }
)

// Action creators are generated for each case reducer function
export const { setError, setLogout } = authSlice.actions

export default authSlice.reducer

