/* eslint-disable @typescript-eslint/no-explicit-any */
import localforage from 'localforage'

/**
 * Offline Browser storage utility using localforage.
 * https://github.com/localForage/localForage
 *
 * Localforage uses async storage. The call should be made using async/await or then/catch.
 */
class LocalStore {
  private store: LocalForage;

  constructor() {
    localforage.config({
      driver: localforage.INDEXEDDB,
      name: 'opendome',
      version: 1.0,
      size: 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName: 'opendome-store',
      description: 'local indexed db storage for opendome',
    });

    this.store = localforage;
  }

  async clear(): Promise<void> {
    console.log('clear')
    return this.store.clear();
  }

  async getItem(key: string): Promise<any> {
    console.log('getItem', key)
    return this.store.getItem(key);
  }

  async removeItem(key: string): Promise<void> {
    console.log('removing', key)
    return this.store.removeItem(key);
  }

  async getremoveItem(key: string): Promise<any> {
    console.log('getremoveItem', key)
    return this.getItem(key).then(i => i).finally(() => this.removeItem(key))
  }

  async setItem(key: string, value: any): Promise<any> {
    console.log('setItem', key)
    return this.store.setItem(key, value);
  }

  async setItems(objects: { [key: string]: any }): Promise<any[]> {
    console.log('setItems')
    const promises: Promise<any>[] = [];
    Object.keys(objects).forEach((key: any) => {
      promises.push(this.setItem(key, objects[key]));
    });
    return Promise.all(promises);
  }
}

export default LocalStore;
