import FLYBITS from 'consts/flybits'

import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'

export interface EnvState {
    tenantId: string,
    deployment: string,
}

const initialState: EnvState = {
    tenantId: FLYBITS.DEFAULT_TENANT,
    deployment: FLYBITS.DEFAULT_DEPLOYMENT,
}

export const envSlice = createSlice({
    name: 'env',
    initialState,
    reducers: {
        setTenant: (state, action: PayloadAction<string>) => {
            state.tenantId = action.payload
        },
        setDeployment: (state, action: PayloadAction<string>) => {
            state.deployment = action.payload
        }, 
    },
})

// Action creators are generated for each case reducer function
export const { setTenant, setDeployment } = envSlice.actions

export default envSlice.reducer
