import { RootState } from 'configureStore'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { newApiToken } from 'utils/api'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { activate } from './services'

const MAX_LENGTH = 6

const Activate = () => {
    const { token = '' } = useSelector((state: RootState) => state.auth)
    const { deployment } = useSelector((state: RootState) => state.env)

    const apiToken = newApiToken(token, deployment)
    const [ code, setCode ] = useState('')

    const [ isError, setError ] = useState(false)
    const [ errorMsg, setErrorMsg ] = useState('')

    const navigate = useNavigate()

    const handleActivate = async () => {
        console.log('Activating', code)
        const status = await activate(apiToken, code)
        if (status) {
            navigate('/dashboard/setup')
        } else if (false === status) {
            setError(true)
            setErrorMsg('Incorrect Activation Provided')
        } else {
            setError(true)
            setErrorMsg('Unexpected Error occured')
        }
    }

    return <>
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography sx={{ minWidth: 180 }}>Enter the code displayed on your Headset/Screen</Typography>
                <TextField sx={{ width: 'max-content' }} required variant="outlined" value={code} inputProps={{ maxLength: MAX_LENGTH, textalign: 'center' }} onChange={({ target: { value } }) => {
                    const v = value.toLocaleUpperCase()
                    const regex = /^[0-9A-Z]+$/
                    if (v.match(regex) || '' === v) {
                        setCode(v);
                    }
                }}/>
                <Button disabled={code.length !== MAX_LENGTH} id={`activate`} variant="outlined" onClick={() => {
                    handleActivate()
                }}>Activate</Button>
            </Box>
            <Dialog
                open={isError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Unable to Activate Code</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errorMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setError(false)
                        setErrorMsg('')
                    }} autoFocus>OK</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </>
}

export default Activate
