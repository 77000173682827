export const FLYBITS = {
    CONTROL_TOWER_URL: 'https://controltower.flybits.com',
    CONTROL_TOWER_SIGN_OUT_URL: 'https://controltower.flybits.com/app/signout',
    CONTROL_TOWER_API_HOST: 'https://api.controltower.flybits.com',
    AUTH_PROXY_URL: 'https://appauth.solutions-us.flybits.com',
    WS_URL: 'wss://ws.opendome.flybits.dev',
    PNS_I9N_API_HOST: 'https://i9n.pns.flybits.dev',
    API_HOST: 'https://api.opendome.flybits.dev',
    SANDBOX_URL: 'https://www.sandbox.pns.flybits.dev',

    DEFAULT_TENANT: '',
    DEFAULT_DEPLOYMENT: 'api.demo.flybits.com',
    DEFAULT_PATH: '/dashboard/activate',

    ARTIFACT_URL: 'https://artifacts.opendome.flybits.dev',
}

export const HEADERS = {
    X_AUTHORIZATION: 'X-Authorization',
    X_API_HOST: 'x-api-host',
}

export default FLYBITS

export const DEPLOYMENT_LIST = [
    FLYBITS.DEFAULT_DEPLOYMENT
]

export const WS = {
    ACTIONS: {
        MANAGEMENT_HEARTBEAT: 'management/heartbeat',
        
        PRESENTATION_REQUEST: '@presentation/request',
        PRESENTATION_INVITE: '@presentation/invite',
        PRESENTATION_AUDIENCE_TAKEN: '@presentation/audienceTaken',
        REMOTE_PREVIOUS: '@remote/previous',
        REMOTE_NEXT: '@remote/next',
        REMOTE_RESET: '@remote/reset',
        REMOTE_DAYS_OF_INVENTORY: '@remote/dayOfInventory',
        REMOTE_DAYS_OF_RECEIVABLES: '@remote/dayOfReceivables',
        REMOTE_DAYS_OF_PAYABLES: '@remote/dayOfPayables',
    },
    RESPONSES: {
        PRESENTATION_REQUEST: '!response/@presentation/request',
        PRESENTATION_INVITE: '!response/@presentation/invite',
    },
    INFOS: {
        PRESENTATION_ACCEPT: '!presentation/accept',
    },
}
