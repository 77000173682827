import { FLYBITS, HEADERS } from 'consts/flybits'
import { apiToken, ApiToken } from 'utils/api'
import { axiosRetryInstance as axios } from 'utils/axios'

export type Config = {
    [k: string]: any
}

export const getConfig = async <T extends Config> (token: ApiToken, configName: string, entity: string = 'user'): Promise<T> => {
    const t = apiToken(token)
    const response = await axios.get(`${FLYBITS.PNS_I9N_API_HOST}/waterfall-configs/${configName}`, {
        headers: {
            [HEADERS.X_AUTHORIZATION]: t.accessToken,
            [HEADERS.X_API_HOST]: t.apiHost,
        },
        params: {
            entity,
        },
    })
    return response.data
}

export const getTenantConfig = async (token: ApiToken, configName: string) => getConfig(token, configName, 'tenant')
export const getDeviceConfig = async (token: ApiToken, configName: string) => getConfig(token, configName, 'device')

export const saveConfig = async <T extends Config> (token: ApiToken, configName: string, config: Config, entity: string = 'user'): Promise<T> => {
    const t = apiToken(token)
    const response = await axios.post(`${FLYBITS.PNS_I9N_API_HOST}/waterfall-configs/${configName}`, config, {
        headers: {
            [HEADERS.X_AUTHORIZATION]: t.accessToken,
            [HEADERS.X_API_HOST]: t.apiHost,
        },
        params: {
            entity,
        },
    })
    return response.data
}

export const deleteConfig = async <T extends Config> (token: ApiToken, configName: string, entity: string = 'user'): Promise<T> => {
    const t = apiToken(token)
    const response = await axios.delete(`${FLYBITS.PNS_I9N_API_HOST}/waterfall-configs/${configName}`, {
        headers: {
            [HEADERS.X_AUTHORIZATION]: t.accessToken,
            [HEADERS.X_API_HOST]: t.apiHost,
        },
        params: {
            entity,
        },
    })
    return response.data
}