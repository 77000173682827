import Loading from 'icons/loading.js'

const getIcon = (iconName: string, props: any) => {
  if (typeof iconName !== 'string') {
    throw new Error('parameter 1 iconName needs to be a string');
  } else if (props && typeof props !== 'object') {
    throw new Error('parameter 2 props needs to be an object');
  }
  const ICONS: { [k: string]: JSX.Element } = {
    loading: <Loading {...props} />,
  };
  return ICONS[iconName];
};

export default getIcon;
