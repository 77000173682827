// Spring Animation Defaults

// Fades
export const fadeIn = {
    from: { opacity: 0 },
    to: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 250 },
};
