
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const E403 = () => {
    return (
        <div>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    403
                </Typography>
            </Box>
        </div>
    )
}

export default E403
