import axios, { AxiosError } from 'axios'
import axiosRetry, {
    exponentialDelay, IAxiosRetryConfig, isNetworkError, isRetryableError
} from 'axios-retry'

const is429Error = (e: AxiosError) => e.response && e.response.status === 429
export const isNetworkRetryableError = (e: AxiosError) => is429Error(e) || isNetworkError(e) || isRetryableError(e)

export const newInstanceWithRetry = (axiosRetryConfig?: IAxiosRetryConfig) => {
  const a = axios.create()
  axiosRetry(a, {
      retryDelay: exponentialDelay,
      retryCondition: isNetworkRetryableError,
      ...axiosRetryConfig,
  })
  return a
}

export const axiosRetryInstance = newInstanceWithRetry()
