import { FLYBITS, HEADERS } from 'consts/flybits'
import { apiToken, ApiToken } from 'utils/api'
import { axiosRetryInstance as axios } from 'utils/axios'
import { getConfig } from 'services/config'

const CONFIG_NAME = 'opendome-nav'
type Nav = {
    static?: {
        [r: string]: boolean | {}
    },
    permission?: {
        [r: string]: number | {}
    }
}

export const getNav = async (token: ApiToken): Promise<Nav> => await getConfig(token, CONFIG_NAME)
