// import SvgIcon from '@mui/material/SvgIcon';
// import './style.scss'

import FlybitsOpenDomeLogo from 'assets/images/flybits-open-dome-logo-colour.svg'
import { RootState, useAppDispatch } from 'configureStore'
import FLYBITS from 'consts/flybits'
import { setLogout } from 'features/auth/slice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { getConfig, getTenantConfig } from 'services/config'
import { newApiToken } from 'utils/api'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CodeIcon from '@mui/icons-material/Code'
import Logout from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'

import { getNav } from './services'

const drawerWidth: number = 240;
interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
)

type Config = Awaited<ReturnType<typeof getConfig>>

const convertAsset = (config: Config = {}) => {
    return {
        app_bar_logo: config.app_bar_logo || FlybitsOpenDomeLogo,
        app_bar_logo_alt: config.app_bar_logo_alt || 'Company Logo',

        drawer_logo: config.drawer_logo || FlybitsOpenDomeLogo,
        drawer_logo_alt: config.drawer_logo_alt || 'OpenDome Logo',
    }
}


const getPath = (s: string) => `/dashboard/${s}`

const Protected = () => {

    const { token = '', isAuthenticated, user: { firstName = '', lastName = '', projectId = '', id = '', currentDeviceId = '', level = 0 } = {} } = useSelector((state: RootState) => state.auth)
    const { tenantId, deployment } = useSelector((state: RootState) => state.env)

    const apiToken = newApiToken(token, deployment)

    const [ links, setLinks ] = useState<string[]>() 
    
    const [ open, setOpen ] = useState(true)
    const toggleDrawer = () => {
        setOpen(!open);
    }

    const [ assets, setAssets ] = useState<ReturnType<typeof convertAsset>>(convertAsset({}))
    const [ isReady, setReady ] = useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const handleLogout = () => {
        console.log('logout')
        dispatch(setLogout())
    }


    const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

    console.log('isAuthenticated', isAuthenticated, 'tenantId', tenantId, 'deployment', deployment)

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login')
        } else {
            (async () => {
                const navs = await getNav(apiToken)
                const links = new Set<string>()
                const { static: s = {}, permission: p = {}  } = navs
                Object.entries(s).filter(([_, v]) => true === v /* we only support bool */).forEach(([k, _]) => links.add(k))
                Object.entries(p).filter(([_, v]) => level >= v /* we only support number */).forEach(([k, _]) => links.add(k))
                setLinks(Array.from(links))
                setReady(true)
            })()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (links) {
            // Sloopy, but works
            const foundRoute = links.find(l => location.pathname === getPath(l))
            if (!foundRoute) {
                navigate('/404')
            }
        }
    }, [links])

    return (!isReady) ? (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>
    ) : (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar open={open} color="inherit" elevation={0}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none'}),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {/* <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Dashboard
                        </Typography> */}
                        <Box
                            component="img"
                            sx={{
                                height: 48,
                                margin: 'auto'
                            }}
                            src={assets.app_bar_logo}
                            alt={assets.app_bar_logo_alt}
                        />
                        <IconButton color="inherit">
                            <Badge badgeContent={0} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box sx={{ 
                    display: 'flex',
                    position: 'fixed', 
                    left: 0,
                    width: '100%',
                    height: '100%',
                    }} component="nav">
                    <Drawer variant="permanent" open={open} className="drawer">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                            >
                                <Toolbar
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        px: [1],
                                    }}
                                >
                                    <Link href="./" className='opendome-logo'>
                                        <img src={assets.drawer_logo} alt={assets.drawer_logo_alt} />
                                    </Link>
                                    <IconButton onClick={toggleDrawer}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </Toolbar>
                                <Divider sx={{ my: 0 }} />
                                <List component="nav">
                                    <ListItemButton onClick={() => {navigate('/dashboard/activate')}} className="nav-btn">
                                        <ListItemIcon>
                                            <CodeIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Activate" className="nav-title" />
                                    </ListItemButton>
                                    <Box
                                        component="div"
                                        sx={{
                                            height: '100%',
                                            display: 'flex',
                                        }}
                                    />
                                </List>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Divider sx={{ my: 1 }} />
                                <ListItem className="nav-btn">
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={!firstName && !lastName ? `Anonymous` :  `${firstName} ${lastName}`} className="nav-title" />
                                </ListItem>

                                <ListItemButton onClick={() => {
                                    window.open(`${FLYBITS.SANDBOX_URL}/#/sso?tenantId=${tenantId}&deployment=${deployment}`)
                                }} className="nav-btn">
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Settings" className="nav-title" />
                                </ListItemButton>

                                <ListItemButton onClick={handleLogout} className="nav-btn">
                                    <ListItemIcon>
                                        <Logout />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" className="nav-title" />
                                </ListItemButton>
                            </Box>
                        </Box>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'stretch',
                            overflow: 'scroll',
                        }}
                    >
                        <Offset />
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Protected
