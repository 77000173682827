import { FLYBITS, HEADERS } from 'consts/flybits'
import { apiToken, ApiToken } from 'utils/api'
import { axiosRetryInstance as axios } from 'utils/axios'

// Services
export const activate = async (token: ApiToken, code: string): Promise<boolean | undefined> => {
    const t = apiToken(token)
    const response = await axios.put(`${FLYBITS.API_HOST}/welcome/code/${code}`, {}, {
        headers: {
            [HEADERS.X_AUTHORIZATION]: t.accessToken,
            [HEADERS.X_API_HOST]: t.apiHost,
        },
        validateStatus: (status) => true,
    })
    const statusCode = response.status
    if (200 === statusCode) {
        return true
    } else if (404 === statusCode) {
        return false
    }
}
