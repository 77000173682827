import { ReactComponent as Error404Image } from 'assets/images/error-404.svg'
import React from 'react'

const CONTROL_TOWER_ORGS_PAGE_URI =
  process.env.REACT_APP_CONTROL_TOWER_ORGS_PAGE || 'https://controltower.flybits.com/signin';

type TProps = {
  isError: boolean;
  isExtAuthError?: boolean;
};

const NotFoundPage = ({ isError, isExtAuthError }: TProps) => {
  const goToControlTower = () => window.location.assign(CONTROL_TOWER_ORGS_PAGE_URI);

  return (
    <div className="not_found">
      <div className="not_found__content">
        <div className="not_found__content__wrapper">
          <div className="not_found__content__heading-text">
            {isError ? (
              <>Something went wrong.</>
            ) : (
              <>
                <div>We could not fetch your project.</div>
                {isExtAuthError ? (
                  <div>Identity provider details are invalid.</div>
                ) : (
                  <div> Click below to return to Control Tower and select a project.</div>
                )}
              </>
            )}
          </div>
          {isError && isExtAuthError ? (
            <div className="not_found__content__description-text">
              Please check further details with your identity provider
            </div>
          ) : (
            <div className="not_found__content__description-text">
              We encountered an error while trying to process your request.
            </div>
          )}
          {!isExtAuthError && (
            <button
              onClick={goToControlTower}
            />
          )}
        </div>
      </div>
      <div className="not_found__figure">
        <Error404Image />
      </div>
    </div>
  );
}

export default NotFoundPage;
