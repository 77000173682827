import { RootState, useAppDispatch } from 'configureStore'
import FLYBITS from 'consts/flybits'
import { setLogout } from 'features/auth/slice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createSearchParams, useNavigate } from 'react-router-dom'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

const path = FLYBITS.DEFAULT_PATH

const EnvSelector = () => {

    const navigate = useNavigate()

    const { tenantId, deployment } = useSelector((state: RootState) => state.env)

    const [tid, setTenantId] = useState(tenantId)
    const [dpmt, setDeployment] = useState(deployment)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setLogout())
    }, [])

    const handleLoginCT = () => {
        navigate({
            pathname: '/sso',
            search: `?${createSearchParams({
                tenantId: tid,
                deployment: dpmt,
                path,
            })}`,
        })
    }

    const handleLoginAnon = () => {
        navigate({
            pathname: '/anonymous',
            search: `?${createSearchParams({
                tenantId: tid,
                deployment: dpmt,
                path,
            })}`,
        })
    }

    const handleLoginJwt = () => {
        navigate({
            pathname: '/jwt',
            search: `?${createSearchParams({
                tenantId: tid,
                deployment: dpmt,
                path,
            })}`,
        })
    }

    return (
        <div>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Select an Environment
                </Typography>
                <Box
                    component="form"
                    sx={{ mt: 1 }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            id="tenantId"
                            label="Tenant Id (or JWT)"
                            defaultValue={tenantId}
                            onChange={e => setTenantId(e.target.value)}
                            required
                            fullWidth
                            margin="normal"
                            autoFocus
                        />
                        <TextField
                            id="deployment"
                            label="Deployment"
                            defaultValue={deployment}
                            onChange={e => setDeployment(e.target.value)}
                            required
                            fullWidth
                            margin="normal"
                        />
                        <Button sx={{ mt: 3, mb: 2 }} fullWidth disabled={(!tid || !dpmt)} variant="text" onClick={handleLoginCT}>Login via Control Tower</Button>
                        <Button sx={{ mt: 3, mb: 2 }} fullWidth disabled={(!tid || !dpmt)} variant="text" onClick={handleLoginAnon}>Login Anonymously</Button>
                        <Button sx={{ mt: 3, mb: 2 }} fullWidth disabled={(!tid || !dpmt)} variant="text" onClick={handleLoginJwt}>Login with JWT</Button>
                        <Button sx={{ mt: 3, mb: 2 }} fullWidth disabled={true} variant="text">Login via Google</Button>
                        <Button sx={{ mt: 3, mb: 2 }} fullWidth disabled={true} variant="text">Login with Mobile Push</Button>
                    </div>
                </Box>
            </Box>
        </div>
    );
};

export default EnvSelector;
