import E403 from 'components/shared/403'
import E404 from 'components/shared/404'
import Activate from 'features/activate'
import Anonymous from 'features/auth/components/anonymous'
import AuthCallback from 'features/auth/components/auth-callback'
import Jwt from 'features/auth/components/jwt'
import Logout from 'features/auth/components/logout'
import Protected from 'features/auth/components/protected-outlet'
import SSO from 'features/auth/components/sso'
import Selector from 'features/env'
import { Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'

const AppRoutes = () => {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard/activate" replace />}/>
            <Route path="/login" element= {<Selector />}/>
            <Route path="/logout" element= {<Logout />}/>
            <Route path="/sso" element= {<SSO />}/>
            <Route path="/anonymous" element= {<Anonymous />}/>
            <Route path="/jwt" element= {<Jwt />}/>
            <Route path="/auth-callback" element={<AuthCallback />} />
            <Route path="/dashboard" element={<Protected />}>
                <Route path="activate" element={<Activate />} />
            </Route>
            <Route path="/403" element={<E403 />} />
            <Route path="/404" element={<E404 />} />
          </Routes>
        </HashRouter>
      </Suspense>
    </div>
  );
}

export default AppRoutes
