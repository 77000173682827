import LoadingIcon from 'components/shared/loading-icon'
import { RootState, useAppDispatch } from 'configureStore'
import FLYBITS from 'consts/flybits'
import { authExchangeToken, setError } from 'features/auth/slice'
import NotFoundPage from 'pages/not-found'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LocalStore from 'services/localstore'

const localStore = new LocalStore();

const AuthCallback = () => {

    const { isAuthenticated, isError } = useSelector((state: RootState) => state.auth)
    const { deployment } = useSelector((state: RootState) => state.env)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [ urlParams ] = useSearchParams()
    const authCode = urlParams.get('code')
    const error = urlParams.get('error')
    const state = (() => {
        let r = {}
        const s = urlParams.get('state')
        try {
            if (s) {
                r = JSON.parse(s)
            }
        } catch (e) {
            console.log('Invalid and ignored state', s)
            try {
                console.log('2nd try')
                const url = window.location.href
                r = JSON.parse(decodeURIComponent(url.split('state=')[1]))
            } catch (e) {
                console.log('2nd attempt fails')
            }
        }
        console.log('state', r)
        return r as {[k: string]: any}
    })()

    console.log('isAuthenticated', isAuthenticated)
    console.log('authCode', authCode)

    useEffect(() => {
        (async () => {
            console.log('inside effect async')
            if (!authCode || error) {
                dispatch(setError(true))
            } else {
                const {
                    codeVerifier,
                    authRedirectUri,
                } = (await localStore.getremoveItem('auth_proxy_payload')) || {}
                console.log('codeVerifier', codeVerifier, 'authRedirectUri', authRedirectUri)
                dispatch(authExchangeToken({ authCode, codeVerifier, authRedirectUri, deployment }))
            }
        })()
    }, [dispatch, authCode])

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                if (state.path) {
                    navigate(state.path)
                } else {
                    navigate(FLYBITS.DEFAULT_PATH)
                }
            }
        })()
    }, [dispatch, isAuthenticated])

    return (
        <>
            <LoadingIcon width={80} height={80} fullScreen visible={!isError} />
            {isError && <NotFoundPage isError />}
        </>
    );
}

export default AuthCallback;
