import { useAppDispatch } from 'configureStore'
import FLYBITS from 'consts/flybits'
import { generateAuthCode } from 'features/auth/services'
import { setLogout } from 'features/auth/slice'
import { setDeployment, setTenant } from 'features/env/slice'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LocalStore from 'services/localstore'

const localStore = new LocalStore();

const SSO = () => {

    const [ urlParams ] = useSearchParams()
    const deployment = urlParams.get('deployment') || FLYBITS.DEFAULT_DEPLOYMENT
    const tenantId = urlParams.get('tenantId')
    const path = urlParams.get('path') || undefined

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        (async () => {
            if (tenantId) {
                dispatch(setTenant(tenantId))
                dispatch(setDeployment(deployment))
                dispatch(setLogout())

                const origin = window.location.origin
                const { authRedirectUri, url, codeVerifier } = await generateAuthCode(tenantId, origin, deployment, path);
                await localStore.setItem('auth_proxy_payload', {
                    codeVerifier,
                    authRedirectUri,
                });
                console.log('url', url)
                window.location.assign(url)
            } else {
                navigate('/login')
            }
        })()
    }, [])

    return (
        <div/>
    );
};

export default SSO;
