import * as Animations from 'consts/animations/spring-animations'
import React, { useEffect, useState } from 'react'
import { animated, useTransition } from 'react-spring'
import getIcon from 'utils/icons'

interface Props {
  width?: number;
  height?: number;
  className?: string;
  text?: string;
  type?: string;
  fullScreen?: boolean;
  visible?: boolean;
}

const LoadingIcon = (props: Props) => {
  const { type, width, height, className, fullScreen, visible, text } = props;
  const [show, setVisibility] = useState(false);
  const animationProps = {
    ...Animations.fadeIn,
    config: {
      duration: 250,
    },
  };
  const transitions = useTransition(show, animationProps);

  useEffect(() => {
    setVisibility(visible || false);
  }, [visible]);

  const loadingBlock = (height = 16) => (
    <div className="content-placeholder-text" style={{ ['--block-height' as string]: `${height}px` }}>
      <span className="content-placeholder-background"></span>
    </div>
  );

  const loadingIcon =
    !type || type === 'circle' ? getIcon('loading', { width, height, className }) : loadingBlock(height);

  return (
    <>
      {' '}
      {transitions((props, item) =>
          item && (
            <animated.div
              // key={item.key}
              // style={props}
              className={`loading__icon_wrapper loading__icon_wrapper_${
                !type || type === 'circle' ? 'circle' : 'block'
              } ${fullScreen ? 'fullscreen' : ''}`}
            >
              {loadingIcon}
              {text && <h3>{text}</h3>}
            </animated.div>
          ),
      )}
    </>
  );
};

export default LoadingIcon;
