import FLYBITS from 'consts/flybits'

const reusePromise = require('reuse-promise').default

type Token = {
    apiHost: string,
    accessToken: string,
}

const getApiHost = (deployment: string) => `https://${deployment}`

export type ApiToken = string | Token

export const apiToken = (token: ApiToken): Token => {
    if (typeof token === 'string') {
        return {
            apiHost: getApiHost(FLYBITS.DEFAULT_DEPLOYMENT),
            accessToken: token,
        } as Token
    }
    return {
        apiHost: getApiHost(token.apiHost),
        accessToken: token.accessToken,
    }
}
export const newApiToken = (accessToken: string, apiHost: string = FLYBITS.DEFAULT_DEPLOYMENT): Token => ({
    apiHost,
    accessToken,
})

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export const cache = <F extends Function> (f: F, opts?: {
    memoize?: boolean,
    serializeArguments?: (args: ArgumentTypes<typeof f>) => string | string
}): F => reusePromise(f, opts)

