import LoadingIcon from 'components/shared/loading-icon'
import { RootState, useAppDispatch } from 'configureStore'
import FLYBITS from 'consts/flybits'
import { authAnonymous, setError, setLogout } from 'features/auth/slice'
import { setDeployment, setTenant } from 'features/env/slice'
import NotFoundPage from 'pages/not-found'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'


const Anonymous = () => {

    const { isAuthenticated, isError } = useSelector((state: RootState) => state.auth)

    const [ urlParams ] = useSearchParams()
    const deployment = urlParams.get('deployment') || FLYBITS.DEFAULT_DEPLOYMENT
    const tenantId = urlParams.get('tenantId')
    const path = urlParams.get('path') || undefined

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        (async () => {
            if (tenantId) {
                dispatch(setTenant(tenantId))
                dispatch(setDeployment(deployment))
                
                dispatch(authAnonymous({tenantId, deployment}))   
            }
        })()
    }, [dispatch, tenantId, deployment])

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                if (path) {
                    navigate(path)
                } else {
                    navigate(FLYBITS.DEFAULT_PATH)
                }
            }
        })()
    }, [dispatch, isAuthenticated])

    return (
        <>
            <LoadingIcon width={80} height={80} fullScreen visible={!isError} />
            {isError && <NotFoundPage isError />}
        </>
    );
};

export default Anonymous;
