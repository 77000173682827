import FLYBITS from 'consts/flybits'
import { UserData, IDTokenDecoded } from 'features/auth/types'
import {
    getCodeChallenge, getCodeVerifier, getFormUrlEncodedParams, getJWTDecoded
} from 'features/auth/utils'
import { axiosRetryInstance as axios } from 'utils/axios'

export const generateAuthCode = async (tenantId: string, origin: string, deployment: string, path = '') => {
    console.log('generateAuthCode', origin, deployment)
    const codeVerifier = getCodeVerifier();
    const codeChallenge = getCodeChallenge(codeVerifier);
    const authRedirectUri = `${FLYBITS.AUTH_PROXY_URL}/auth-callback/${tenantId}`;

    const proxyPayload = {
        origin: encodeURI(`${origin}/#/auth-callback`),
        path: encodeURIComponent(path),
    }

    const params = {
        scope: 'openid',
        response_type: 'code',
        code_challenge_method: 'S256',
        client_id: deployment,
        code_challenge: codeChallenge,
        redirect_uri: authRedirectUri,
        state: JSON.stringify(proxyPayload)
    };

    const authRequestUrl = new URL(`${FLYBITS.CONTROL_TOWER_API_HOST}/auth/request`);
    Object.entries(params).forEach(param => {
        authRequestUrl.searchParams.append(param[0], param[1]);
    });

    return {
        authRedirectUri,
        codeVerifier,
        url: authRequestUrl.href,
    };
}

export const generateIdToken = async (authCode: string, codeVerifier: string, authRedirectUri: string, deployment: string) => {
    console.log('generateIdToken', authCode, codeVerifier, authRedirectUri, deployment)
    const tokenParams = {
        grant_type: 'authorization_code',
        client_id: deployment,
        code_verifier: codeVerifier,
        code: authCode,
        redirect_uri: authRedirectUri,
    };

    const response = await axios.post(`${FLYBITS.CONTROL_TOWER_API_HOST}/auth/token`, getFormUrlEncodedParams(tokenParams), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
    return response.data.id_token as string
}

export const oidcLogin = async (idToken: string, api: string) => {
    console.log('oidcLogin', idToken, api)
    const { aud } = getJWTDecoded<IDTokenDecoded>(idToken);

    const payload = {
        projectId: aud,
        idToken,
        provider: 'flybits',
    };

    console.log(oidcLogin, payload)

    const response = await axios.post(`${api}/sso/auth/oidc`, payload, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    const { data, headers } = response
    console.log(data)
    console.log(headers)
    return {
        user: data as UserData,
        token: headers['x-authorization']
    }
}

export const anonymousLogin = async (tenantId: string, api: string) => {
    const response = await axios.post(`${api}/sso/auth/anonymous`, {
        projectId: tenantId,
    })
    const { data, headers } = response
    console.log(data)
    console.log(headers)
    return {
        user: data as UserData,
        token: headers['x-authorization']
    }
}

export const jwtLogin = async (jwt: string, api: string) => {
    const userData = await getUserData(jwt, api)
    return {
        user: userData,
        token: jwt,
    }
    
}

export const getUserData = async (accessToken: string, api: string): Promise<UserData> => {
    console.log('getUserData', accessToken, api)
    const response = await axios.get(`${api}/sso/auth/me`, {
        headers: {
            Accept: 'application/json',
            'X-Authorization': accessToken,
        },
    })
    return response.data
}
